<template>
  <div>
    <div
      class="educator-card pointer"
      v-on:click="go(educator.id)"
 
    >

      <div class="profile-image">
        <img
          :src="educator.image"
          class="rounded-circle"
        >
      </div>
      <div class="profile-details">
        <div class="profile-socials">
          <h5 class="mb-2">{{educator.name}}</h5>
          <div v-if="educator.facebook && educator.facebook.length"><i class="fa fa-facebook mr-1" />{{educator.facebook}}</div>

          <div v-if="educator.twitter && educator.twitter.length"><i class="fa fa-twitter mr-1" />{{educator.twitter}}</div>
          <div><i class="fa fa-instagram mr-1" />{{educator.instagram.replace('https://www.instagram.com/','').replace('/','')}}</div>

        </div>

        <!-- <div
          v-if="posts.length"
          class="text-danger"
        ><strong>{{posts.length}} Videos</strong>  </div> -->
      </div>
    </div>
  </div>

</template>

<script>
import { db } from "@/db.js";

export default {
  components: {},
  props: ["educator"],
  data: function () {
    return {
      posts: []


    };
  },
  mounted () {

    this.getPostCount(this.educator.id)


  },
  methods: {
    getPostCount (educatorId) {
      this.$bind(
        "posts",
        db
          .collection("posts")
          .where("Educator", "==", educatorId)
          .orderBy("Educator")//needed for missing fields
        // .limit(12)
      ).then(function () {

      });
    },
    go (educatorId) {
      this.$router.push({ name: 'Educator', params: { educatorId: educatorId } })
    }




  },
};
</script>

<style>
.educator-card {
  border: 1px solid rgb(146, 146, 146);
  margin-bottom: 20px;
  height: 150px;
}
.profile-image {
  border: 1px thin red;
  float: left;
}

.profile-image img {
  padding: 10px;
  height: 150px;
  width: 150px;
  max-width: 150px;
  max-height: 150px;
}

.profile-details {
  left: 150px;
  padding: 10px;
  margin-left: 10px;
}
.profile-socials {
  height: 100px;
}
</style>