<template>
  <div>
    <div class="row">
      <div class="col-md-12 mt-3 mb-3">
        <h3>Meet your Educators</h3>
      </div>
    </div>
    <div class="row">

      <div
        v-for="educator in educators"
        :key="educator.id"
        class="col-md-6 col-lg-4"
       
        
      >
        <educator-card :educator="educator" ></educator-card>

      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/db.js";

import EducatorCard from '../components/EducatorCard.vue';

export default {
  components: { EducatorCard },
  data () {
    return {
      educators: null,
      posts:[]
    }
  },
  mounted () {
    this.getEducators();

  },
  methods: {

    showEducator(){
        return false;
    },

    getEducators () {

      this.$bind(
        "educators",
        db
          .collection("educators")
        //  .where("id", "==", "AsQU5GoDfaUvTAnA9bB3")
        .orderBy("name", "asc")
        // .limit(10)
      ).then(() => {


      });



    },
    getPosts(){
      this.$bind(
        "posts",
        db
          .collection("posts")
          .where("Educator", "!=", "")
          .orderBy("Educator")//needed for missing fields
        // .limit(12)
      ).then(function () { 

      });
    },

    




  }
}
</script>

<style>

</style>